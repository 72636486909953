import objectHash from 'object-hash';
import { GridRatio, GridRatioLabel, GridCell } from 'interfaces/app';
import allGrids from 'settings/grids.json';

interface TempGrid {
	cells: GridCell[];
}

export interface Grid extends TempGrid {
	id: string;
}

export default class GridCreator {
	// Margin between different grid positions
	private readonly marginBetweenPositions: number = 20;

	// Margin around the entire grid
	private readonly marginAroundEdge: number = 30;

	// Minimum size of every grid position
	private readonly minGridPositionSize: number = 100;

	// Maximum number of grid positions to generate
	private static readonly maxGridPositions: number = 5;

	// Maximum size ratio between longest and shortest side of each grid position
	private readonly maxPositionRatio = 2.1;

	// The height of the grid area
	private readonly gridHeight: number;

	// The width of the grid area
	private readonly gridWidth: number;

	constructor(
		// Width of the grid
		width: number,
		// Height of the grid
		height: number,
		settings: {
			// Margin around the grid
			marginAroundEdge?: number,
			// Number of pixels between different grid positions
			marginBetweenPositions?: number,
		},
	) {
		if (typeof settings.marginAroundEdge === 'number') {
			this.marginAroundEdge = settings.marginAroundEdge;
		}
		if (typeof settings.marginBetweenPositions === 'number') {
			this.marginBetweenPositions = settings.marginBetweenPositions;
		}

		// Calculate the area the grid will be covering when staying away from grid margins
		this.gridWidth = Math.round(width - 2 * this.marginAroundEdge);
		this.gridHeight = Math.round(height - 2 * this.marginAroundEdge);
	}

	public generateGridCollection(
		requestedGridPositions: number,
	): Grid[] {
		if (requestedGridPositions > GridCreator.maxGridPositions) {
			throw new Error(`Requested number of grid positions is too high. Maximum is ${GridCreator.maxGridPositions}.`);
		}

		const [ratioLabel, ratioWidth, ratioHeight] = this.getBestFittingRatio(
			this.gridWidth,
			this.gridHeight,
		);

		const gridCollection: Grid[] = [];
		const combinations = allGrids[ratioLabel][requestedGridPositions as (1 | 2 | 3 | 4 | 5)];

		const gridRightEdge = Math.round(this.gridWidth / ratioWidth);
		const gridBottomEdge = Math.round(this.gridHeight / ratioHeight);
		const positionPadding = this.marginBetweenPositions / 2;

		combinations.forEach((combo) => {
			const gridCells: GridCell[] = [];

			const results = combo.map((gridCell) => {
				const marginLeft = gridCell.x === 0
					? 0
					: positionPadding;
				const marginTop = gridCell.y === 0
					? 0
					: positionPadding;
				const marginRight = gridCell.x + gridCell.w
					=== gridRightEdge
					? 0
					: positionPadding;
				const marginBottom = gridCell.y + gridCell.h
					=== gridBottomEdge
					? 0
					: positionPadding;
				const x = Math.round((ratioWidth * gridCell.x) + marginLeft + this.marginAroundEdge);
				const y = Math.round((ratioHeight * gridCell.y) + marginTop + this.marginAroundEdge);
				const width = Math.round((ratioWidth * gridCell.w) - marginLeft - marginRight);
				const height = Math.round((ratioHeight * gridCell.h) - marginTop - marginBottom);

				return {
					x,
					y,
					width,
					height,
				};
			});

			const hasInvalidSize = results.find(
				(result) => result.width < this.minGridPositionSize
					|| result.height < this.minGridPositionSize
					|| result.height > result.width * this.maxPositionRatio
					|| result.width > result.height * this.maxPositionRatio,
			);
			if (!hasInvalidSize) {
				results.forEach(
					(result) => {
						gridCells.push({
							x: result.x,
							y: result.y,
							w: result.width,
							h: result.height,
						});
					},
				);
				gridCollection.push({
					id: objectHash(gridCells),
					cells: gridCells,
				});
			}
		});

		return gridCollection;
	}

	/**
	 * Helper function to find best ratio for a given width and height
	 * @param width The width of the grid in pixels
	 * @param height The height of the grid in pixels
	 * @returns [Width ratio, Height ratio]
	 */
	private getBestFittingRatio(
		width: number,
		height: number,
	): [GridRatioLabel, number, number] {
		const availableRatios: GridRatio[] = [
			[3, 2],
			[2, 3],
			[4, 3],
			[3, 4],
			[1, 1],
			[16, 9],
			[9, 16],
			[21, 9],
			[9, 21],
		];
		const requestedRatio = width / height;

		availableRatios.sort((a, b) => {
			const aRatio = a[0] / a[1];
			const bRatio = b[0] / b[1];

			return Math.abs(aRatio - requestedRatio) - Math.abs(bRatio - requestedRatio);
		});

		const bestFittingRatio = availableRatios[0];
		const w = bestFittingRatio[0] > bestFittingRatio[1]
			? (bestFittingRatio[0] / bestFittingRatio[1]) * 1000
			: 1000;
		const h = bestFittingRatio[1] > bestFittingRatio[0]
			? (bestFittingRatio[1] / bestFittingRatio[0]) * 1000
			: 1000;

		return [
			`${bestFittingRatio[0]}_${bestFittingRatio[1]}` as GridRatioLabel,
			width / w,
			height / h,
		];
	}

	public static getMaxGridPositions(): number {
		return GridCreator.maxGridPositions;
	}
}
